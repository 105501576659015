import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import SelectSearch, { fuzzySearch } from 'react-select-search';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


function mapStateToProps(state) {
    return {
        USER: state.USER,
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class OrderingGroupConfirmation extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            DATA: this.prepareLocalData(props.DATA),
            ORDER_DATE: this.getTodayDatePc(),
            DELIVERY_DATE: this.getTomorrowDatePc(),
            DELIVERY_TIME: this.getCurrentDeliveryTime(props.DATA),
            use_discount: false,
            send_to_1c: true,
            SELECTED: [],
            COMMENT: '',

            SHOW_SUPPLIER_SELECT: false,
            SUPPLIERS: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.DATA !== prevProps.DATA) {
            this.setState({ DATA: this.props.DATA, SELECTED: [] })
        }
    }

    prepareLocalData = (DATA) => {
        const currentValues = localStorage.getItem('orderingProductsPrepares')
        let orderingProductsPrepares = {}
        if (currentValues !== null) {
            orderingProductsPrepares = JSON.parse(currentValues)
        }

        let updatedProducts = [...DATA.products]
        for (let i = 0; i < updatedProducts.length; i++) {
            if (orderingProductsPrepares[updatedProducts[i].id]) {
                updatedProducts[i].amount = orderingProductsPrepares[updatedProducts[i].id].amount
                updatedProducts[i].sup_price = orderingProductsPrepares[updatedProducts[i].id].sup_price
            }
        }

        DATA.products = updatedProducts

        return DATA
    }

    getCurrentDeliveryTime = (DATA) => {
        let start = 4;
        let end = 22;

        if (typeof DATA.supplier !== "undefined" && typeof DATA.supplier.delivery_times !== "undefined" && typeof DATA.supplier.delivery_times2 !== "undefined") {
            if (DATA.supplier.delivery_times.length && DATA.supplier.delivery_times2.length) {
                let start_parts = DATA.supplier.delivery_times.split(':');
                start = parseInt(start_parts[0])
                let end_parts = DATA.supplier.delivery_times2.split(':');
                end = parseInt(end_parts[0])
            }
        }

        let local_start = start;
        let local_end = start + 1;

        if (local_start < 10) {
            local_start = '0' + local_start
        }
        if (local_end < 10) {
            local_end = '0' + local_end
        }

        let string = local_start + ':00-' + local_end + ':00';

        return string
    }

    renderDeliveryTimeOptions = () => {
        let start = 4;
        let end = 22;

        if (typeof this.props.DATA.supplier !== "undefined" && typeof this.props.DATA.supplier.delivery_times !== "undefined" && typeof this.props.DATA.supplier.delivery_times2 !== "undefined") {
            let start_parts = this.props.DATA.supplier.delivery_times.split(':');
            start = parseInt(start_parts[0])
            let end_parts = this.props.DATA.supplier.delivery_times2.split(':');
            end = parseInt(end_parts[0])
        }

        let options = []
        for (let i = 4; i < 22; i++) {
            let local_start = i;
            let local_end = i + 1;

            if (local_start < 10) {
                local_start = '0' + local_start
            }
            if (local_end < 10) {
                local_end = '0' + local_end
            }

            let string = local_start + ':00-' + local_end + ':00';
            options.push(<option key={ i } value={ string }>{ string }</option>)
        }

        return options;
    }

    countSum = () => {
        if (this.state.SELECTED.length) {
            let sum = 0;
            for (let i = 0; i < this.state.DATA.products.length; i++) {
                let GP = this.state.DATA.products[i];

                if (this.state.SELECTED.includes(GP.id)) {
                    sum += Math.round(GP.amount * GP.sup_price)
                }
            }

            return sum;
        }

        return 0;
    }

    toggleConfirmation = (id) => {
        let SELECTED = [...this.state.SELECTED]
        if (!SELECTED.includes(id)) {
            SELECTED.push(id)
            this.setState({ SELECTED: SELECTED })
        } else {
            let NEW_SELECTED = [];
            for (let i = 0; i < SELECTED.length; i++) {
                if (SELECTED[i] !== id) {
                    NEW_SELECTED.push(SELECTED[i])
                }
            }
            this.setState({ SELECTED: NEW_SELECTED })
        }
    }

    renderCheckbox = (GP) => {
        return (
            <input
                key={ GP.product.id + '_checkbox' }
                id={ GP.product.id + '_checkbox' }
                type="checkbox"
                onChange={ () => this.toggleConfirmation(GP.product.id) }
                checked={ this.state.SELECTED.includes(GP.product.id) }
            />
        )
    }

    changeAmount = (id, amount) => {
        let DATA = Object.assign({}, this.state.DATA);
        let products = []
        let sup_price = 0
        for (let i = 0; i < DATA.products.length; i++) {
            if (DATA.products[i].id == id) {
                let product = Object.assign({}, DATA.products[i])
                product.amount = amount
                sup_price = product.sup_price
                products.push(product)
            } else {
                products.push(DATA.products[i])
            }
        }

        DATA.products = products;

        this.setState({ DATA: DATA })
        this.recordLocalData(id, sup_price, amount)
    }

    changeSupPrice = (id, sup_price) => {
        let DATA = Object.assign({}, this.state.DATA);
        let products = []
        let amount = 0
        for (let i = 0; i < DATA.products.length; i++) {
            if (DATA.products[i].id == id) {
                let product = Object.assign({}, DATA.products[i])
                product.sup_price = sup_price
                amount = product.amount
                products.push(product)
            } else {
                products.push(DATA.products[i])
            }
        }

        DATA.products = products;

        this.setState({ DATA: DATA, use_discount: false })
        this.recordLocalData(id, sup_price, amount)
    }

    recordLocalData = (id, sup_price, amount) => {
        const currentValues = localStorage.getItem('orderingProductsPrepares')

        let orderingProductsPrepares = {}
        if (currentValues !== null) {
            orderingProductsPrepares = JSON.parse(currentValues)
        }

        orderingProductsPrepares[id] = {
            sup_price: sup_price,
            amount: amount
        }

        localStorage.setItem('orderingProductsPrepares', JSON.stringify(orderingProductsPrepares))
    }

    renderProducts = () => {
        let ROWS = [];
        for (let i = 0; i < this.state.DATA.products.length; i++) {
            let GP = this.state.DATA.products[i];
            let PRODUCT = this.state.DATA.products[i].product;
            let SRC = PRODUCT.thumb && PRODUCT.thumb.length ? CONFIG.API_C_DOMAIN + PRODUCT.thumb : CONFIG.API_C_DOMAIN + '/imgs/no-foto.png';

            let numb = i + 1
            ROWS.push(
                <div className="ordering_product" key={ i }>
                    <div className="op_id">{ numb }</div>
                    <div className="op_image"><img src={ SRC } /></div>
                    <div className="op_title">{ PRODUCT.title }</div>
                    <div className="op_pencil">
                        <a onClick={ () => this.getOtherSuppliers(PRODUCT.id) }><img src="/system_images/green_pencil.png" /></a>
                    </div>
                    <div className="op_to_order">
                        <input
                            key={ GP.id + '_amount' }
                            type="number"
                            min="0"
                            onChange={ input => this.changeAmount(GP.id, input.target.value) }
                            defaultValue={ GP.amount }
                        />
                    </div>
                    <div className="op_sup_price">
                        <input
                            key={ GP.id + '_sup_price' }
                            type="number"
                            min="0"
                            onChange={ input => this.changeSupPrice(GP.id, input.target.value) }
                            value={ GP.sup_price }
                        />
                    </div>
                    <div className="op_order_sum">{ Math.round(GP.amount * GP.sup_price) }</div>
                    <div className="op_order_confirm">{ this.renderCheckbox(GP) }</div>
                </div>
            )
        }

        return ROWS;

    }

    getTodayDatePc = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;

        return today
    }

    getTomorrowDatePc = () => {
        var tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        var dd = String(tomorrow.getDate()).padStart(2, '0');
        var mm = String(tomorrow.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = tomorrow.getFullYear();
        tomorrow = yyyy + '-' + mm + '-' + dd;

        return tomorrow
    }

    getTodayDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = dd + '.' + mm + '.' + yyyy;

        return today
    }

    selectOrDeselectAll = () => {
        if (this.state.SELECTED.length == this.state.DATA.products.length) {
            this.setState({ SELECTED: [] })
        } else {
            let SELECTED = []
            for (let i = 0; i < this.state.DATA.products.length; i++) {
                SELECTED.push(this.state.DATA.products[i].id)
            }
            this.setState({ SELECTED: SELECTED })
        }
    }

    getClass = () => {
        if (this.state.SELECTED.length == 0) {
            return 'inactive'
        }

        return ''
    }

    changeDeliveryDate = (date) => {
        this.setState({ DELIVERY_DATE: date })
    }

    changeOrderDate = (date) => {
        this.setState({ ORDER_DATE: date })
    }

    submitGroupOrder = async () => {
        if (!this.state.SAVING) {
            this.setState({ SAVING: true })

            let products = []
            let reset_products = []

            for (let i = 0; i < this.state.DATA.products.length; i++) {
                let GP = this.state.DATA.products[i];

                if (this.state.SELECTED.includes(GP.id)) {
                    let product = {
                        id: GP.id,
                        title: GP.title,
                        amount: GP.amount,
                        sup_price: GP.sup_price
                    }

                    products.push(product)
                    reset_products.push(GP.id)
                }
            }

            try {
                let SEND_DATA = {
                    action: 'admin_createOrderToSupplier',
                    supplier_id: this.state.DATA.id,
                    supplier_title: this.state.DATA.title,
                    order_date: this.state.ORDER_DATE,
                    delivery_date: this.state.DELIVERY_DATE,
                    delivery_time: this.state.DELIVERY_TIME,
                    products: products,
                    use_discount: this.state.use_discount,
                    send_to_1c: this.state.send_to_1c,
                    comment: this.state.COMMENT,
                    apikey: this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    this.setState({ COMMENT: '', SAVING: false })
                    localStorage.setItem('orderingProductsPrepares', JSON.stringify({}))
                    this.props.resetSupplierProducts(this.state.DATA.id, this.state.DATA.title, reset_products)
                } else {
                    this.setState({ SAVING: false })
                }
            } catch (error) {
                this.setState({ SAVING: false })
            }
        }
    }

    getTotalsClass = (sum, minimal) => {
        if (sum > 0 && minimal > 0) {
            if (sum < minimal) {
                return ' errored';
            }
        }

        return '';
    }

    renderMore2OrderMessage = (sum, minimal) => {
        if (sum > 0 && minimal > 0) {
            if (sum < minimal) {
                let diff = minimal - sum;
                return (
                    <div className="order_more">Надо добрать { diff } руб</div>
                )
            }
        }
    }

    getOtherSuppliers = async (id) => {
        this.setState({ SHOW_SUPPLIER_SELECT: false, SUPPLIERS: false, SUP_LOADED: false })
        try {
            let SEND_DATA = {
                action: 'admin_getProductSuppliers',
                id: id,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({ SUPPLIERS: data, SUP_LOADED: true, SHOW_SUPPLIER_SELECT: id })
            }
        } catch (error) {
        }
    }

    hideSuppliersModal = () => {
        this.setState({ SHOW_SUPPLIER_SELECT: false, SUPPLIERS: false, SUP_LOADED: false })
    }

    setSupplierToOrdering = (product, supplier) => {
        this.setState({ SHOW_SUPPLIER_SELECT: false })
        this.props.setSupplierToOrdering(product, supplier)
    }

    setAnotherSupplierToOrdering = (old_supplier, supplier) => {
        this.setState({ SHOW_TOTAL_ANOTHER_SUPPLIER_SELECT: false })
        this.props.setAnotherSupplierToOrdering(old_supplier, supplier)
    }

    showSuppliersSelect = () => {
        if (this.state.SHOW_SUPPLIER_SELECT) {
            let SUPS = [];
            if (this.state.SUPPLIERS) {
                for (let i = 0; i < this.state.SUPPLIERS.length; i++) {
                    SUPS.push(
                        <div key={ i }>
                            <a onClick={ () => this.setSupplierToOrdering(this.state.SHOW_SUPPLIER_SELECT, this.state.SUPPLIERS[i].title) }>
                                <span>{ this.state.SUPPLIERS[i].title }<br />{ this.state.SUPPLIERS[i].price } руб от { this.state.SUPPLIERS[i].date }</span>
                            </a>
                        </div>
                    )
                }
            }

            if (this.state.SUP_LOADED && (!this.state.SUPPLIERS || !this.state.SUPPLIERS.length)) {
                SUPS = <h5>К сожалению поставщиков в истории нет!</h5>
            }

            return (
                <div className="or_modal">
                    <div className="or_overlay" onClick={ () => this.hideSuppliersModal() }></div>
                    <div className="or_body_ns">
                        <a className="close-modal" onClick={ () => this.hideSuppliersModal() }>
                            <img src="/system_images/close.png" />
                        </a>
                        <a className="remove_product_from_order" onClick={ () => {
                            this.props.resetSupplierProducts(this.state.DATA.id, this.state.DATA.title, [this.state.SHOW_SUPPLIER_SELECT]);
                            this.hideSuppliersModal();
                        } }>Удалить товар из заказа</a>
                        <h6>Выберите поставщика:</h6>
                        <div className="or_suppliers_list">
                            { SUPS }
                        </div>
                        <h6>Другой поставщик:</h6>
                        { this.renderOtherSupplier() }
                    </div>
                </div>
            )
        }
    }

    renderOtherSupplier = () => {

        return (
            <div className="select_search_holder">
                <SelectSearch
                    filterOptions={ fuzzySearch }
                    search={ true }
                    id="supplierInModal"
                    name="supplierInModal"
                    options={ this.props.SUPPLIERS_OPTIONS }
                    placeholder="Выбрать поставщика"
                    onChange={ (val) => this.setSupplierToOrdering(this.state.SHOW_SUPPLIER_SELECT, val) }
                    value={ '' }
                />
                <img className="bangled" src="/system_images/blue_angle_down.png" />
            </div>
        )
    }

    changeTotalSupplier = () => {
        this.setState({ SHOW_TOTAL_ANOTHER_SUPPLIER_SELECT: true })
    }

    renderTotalAnotherSupplierList = () => {
        if (this.state.SHOW_TOTAL_ANOTHER_SUPPLIER_SELECT) {
            return (
                <div className="or_modal">
                    <div className="or_overlay" onClick={ () => this.setState({ SHOW_TOTAL_ANOTHER_SUPPLIER_SELECT: false }) }></div>
                    <div className="or_body_ns">
                        <a className="close-modal" onClick={ () => this.setState({ SHOW_TOTAL_ANOTHER_SUPPLIER_SELECT: false }) }>
                            <img src="/system_images/close.png" />
                        </a>
                        <h6>Выбрать другого поставщика:</h6>
                        { this.renderTotalOtherSupplier() }
                    </div>
                </div>
            )
        }
    }

    renderTotalOtherSupplier = () => {
        let ids = []
        for (let i = 0; i < this.state.DATA.products.length; i++) {
            ids.push(this.state.DATA.products[i].id)
        }

        return (
            <div className="select_search_holder">
                <SelectSearch
                    filterOptions={ fuzzySearch }
                    search={ true }
                    id="supplierInModal2"
                    name="supplierInModal2"
                    options={ this.props.SUPPLIERS_OPTIONS }
                    placeholder="Выбрать поставщика"
                    onChange={ (val) => this.setAnotherSupplierToOrdering(ids, val) }
                    value={ '' }
                />
                <img className="bangled" src="/system_images/blue_angle_down.png" />
            </div>
        )
    }

    resetPrices = () => {
        let DATA = Object.assign({}, this.state.DATA);
        let products = []
        for (let i = 0; i < DATA.products.length; i++) {
            let product = Object.assign({}, DATA.products[i])
            product.sup_price = 0
            products.push(product)
        }

        DATA.products = products;

        this.setState({ DATA: DATA })
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    renderDebt = () => {
        if (this.state.DATA.supplier.debt > 0) {
            return <small>Задолженность: { this.numberWithCommas(this.state.DATA.supplier.debt) } руб</small>
        }
    }

    render() {
        let sum = this.countSum();
        let minimal = parseInt(this.state.DATA.supplier.minimal_sum)
        return (
            <>
                <div className="ordering_confirm_full_block">
                    { this.showSuppliersSelect() }
                    <div className="ordering_confirm_block">
                        <div className="ordering_confirm_zero">
                            Поставщик
                        </div>
                        <div className="ordering_confirm_one">
                            <div className="ordering_confirm_numb">
                                { this.state.DATA.numb }
                            </div>
                            <div className="ordering_confirm_title">
                                { this.state.DATA.title } { this.renderDebt() }
                            </div>
                            <a className="pencilness" onClick={ () => this.changeTotalSupplier() }><img src="/system_images/green_pencil.png" /></a>
                        </div>
                        <label className="ordering_label_block">
                            <input type="checkbox" onChange={ () => this.setState({ use_discount: !this.state.use_discount }) } checked={ this.state.use_discount } />
                            <span>Скинуть цену на 10% при формировании заказа</span>
                        </label>
                        <label className="ordering_label_block">
                            <input type="checkbox" onChange={ () => this.setState({ send_to_1c: !this.state.send_to_1c }) } checked={ this.state.send_to_1c } />
                            <span>Отправить в 1с</span>
                        </label>
                        <div className="ordering_confirm_two">
                            <div className="ordering_header">
                                <div className="op_id"></div>
                                <div className="op_image"></div>
                                <div className="op_title">наименование</div>
                                <div className="op_pencil"></div>
                                <div className="op_to_order">заказ шт/кг</div>
                                <div className="op_sup_price">цена закупки</div>
                                <div className="op_order_sum">сумма</div>
                                <div className="op_order_confirm">под-ие</div>
                            </div>
                            <div>
                                { this.renderProducts() }
                            </div>
                            <div className="flexed_ordering">
                                <div className={ "ordering_total_sum " + this.getTotalsClass(sum, minimal) }>
                                    <span>Итого сумма заказа:</span>
                                    <span className="big">{ sum } руб</span>
                                </div>
                                { this.renderMore2OrderMessage(sum, minimal) }
                            </div>
                            <div className="ordering_group_comment">
                                <textarea onChange={ (input) => this.setState({ COMMENT: input.target.value }) } placeholder="Комментарий" value={ this.state.COMMENT }></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="ordering_confirm_total">
                        <a className="ordering_reset_prices" onClick={ () => this.resetPrices() }>
                            Обнулить цены
                        </a>
                        <div>
                            <span>Заказ:</span>
                            <span>{ this.state.SELECTED.length } поз</span>
                        </div>
                        <div>
                            <span>Сумма:</span>
                            <span>{ this.countSum() } руб</span>
                        </div>
                        <div>
                            <span>Дата заказа:</span>
                            <span>
                                <input
                                    type="date"
                                    name="order_date"
                                    onChange={ (input) => this.changeOrderDate(input.target.value) }
                                    value={ this.state.ORDER_DATE }
                                />
                            </span>
                        </div>
                        <div>
                            <span>Дата поставки:</span>
                            <span>
                                <input
                                    type="date"
                                    name="delivery_date"
                                    onChange={ (input) => this.changeDeliveryDate(input.target.value) }
                                    value={ this.state.DELIVERY_DATE }
                                />
                            </span>
                        </div>
                        <div>
                            <span>Время поставки:</span>
                            <span>
                                <select
                                    name="delivery_time"
                                    onChange={ (select) => this.setState({ DELIVERY_TIME: select.target.value }) }
                                    value={ this.state.DELIVERY_TIME }
                                >
                                    { this.renderDeliveryTimeOptions() }
                                </select>
                            </span>
                        </div>
                        <a className="ordering_confirm_btn" onClick={ () => this.selectOrDeselectAll() }>
                            { this.state.SELECTED.length == this.state.DATA.products.length ? 'Отменить все' : 'Подтвердить все' }
                        </a>
                        <a className={ 'submit_order_to_suppliers ' + this.getClass() } onClick={ () => this.state.SELECTED.length > 0 ? this.submitGroupOrder() : null }>
                            { this.state.SAVING ? 'Формируем...' : 'Сформировать заказ' }
                        </a>
                    </div>
                </div>
                { this.renderTotalAnotherSupplierList() }
            </>
        )
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OrderingGroupConfirmation);