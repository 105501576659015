import React, {Component} from 'react';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import SelectSearch, {fuzzySearch} from 'react-select-search';

import SupplierEditForm from './../../../APP/COMPONENTS/ContentParts/SupplierEditForm';

function mapStateToProps(state) {
    return {
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        PRODUCTS: state.PRODUCTS,
        USER: state.USER,
        CATS: state.CATS,
        HIDDEN: state.HIDDEN
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class OrderingProductRow extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            IMPORTANT: parseInt(this.props.PRODUCT.important) == 1 ? true : false,
            SHOW_SUPPLIER_SELECT: false,
            SHOW_SUPPLIER_EDIT: false,
            SUPPLIERS: false,
            SALES_SELECTED: {}
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.PRODUCT.id !== prevProps.PRODUCT.id) {
            this.setState({
                IMPORTANT: parseInt(this.props.PRODUCT.important) == 1 ? true : false,
                SHOW_SUPPLIER_SELECT: false,
                SUPPLIERS: false
            })
        }
    }

    getSupplier = (id, supplier, suppliers) =>
    {
        let useSupplier = suppliers.filter(sup => {
            if (sup.id == supplier) {
                return true
            }

            return false
        })

        if (useSupplier.length) {
            return useSupplier[0].title
        }

        return false
    }

    toggleImportance = async () => {
        if (parseInt(this.props.USER.ID) == 344545 || parseInt(this.props.USER.ID) == 1) {
            try {
                let SEND_DATA = {
                    action: 'admin_updateImportantProduct',
                    id: this.props.PRODUCT.id,
                    apikey: this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    this.setState({IMPORTANT: ! this.state.IMPORTANT})
                }
            } catch (error) {
            }
        }
    }

    getProductGroups = (P_GROUPS) =>
    {
        let GROUPS = [];
        try {
        for (let i = 0 ; i < this.props.CATS.length; i++) {
            let group_1 = this.props.CATS[i];

            if (P_GROUPS.includes(group_1.id)) {
                GROUPS.push(group_1.title)
            }

            for (let j = 0; j < group_1.children.length; j++) {
                let group_2 = group_1.children[j];

                if (P_GROUPS.includes(group_2.id)) {
                    GROUPS.push(group_2.title)
                }

                for (let k = 0; k < group_2.children.length; k++) {
                    let group_3 = group_2.children[k];
                    
                    if (P_GROUPS.includes(group_3.id)) {
                        GROUPS.push(group_3.title)
                    }
                }
            }
        }

        return GROUPS.join(', ')
    } catch (e) {
        console.log(e)
    }

    return '';
    }

    renderFav = () =>
    {
        let favheart = '/system_images/favs_empty.png';
        if (this.state.IMPORTANT) {
            favheart = '/system_images/favs_full.png';
        }

        return <div key={this.props.PRODUCT.id + "_fav"} className="op_fav" onClick={() => this.toggleImportance()}><img src={favheart}/></div>
    }

    changeMinimumQuanity = (amount) =>
    {
        this.props.setMinimumQuantity(this.props.PRODUCT.id, amount)
    }

    changeMaximumQuanity = (amount) =>
    {
        this.props.setMaximumQuantity(this.props.PRODUCT.id, amount)
    }

    changeDaysAmount = (amount) =>
    {
        this.props.setSelectedAmount(this.props.PRODUCT.id, amount)
    }

    changeProductsAmount = (amount) =>
    {
        this.props.setSelectedAmountValue(this.props.PRODUCT.id, amount)
    }

    renderCheckbox = () =>
    {
        let PRODUCT = this.props.PRODUCT;
        if (this.props.SELECTED[PRODUCT.id]) {
            return (
                <input 
                    key={PRODUCT.id + '_checkbox'}
                    id={PRODUCT.id + '_checkbox'}
                    type="checkbox" 
                    onChange={ () => this.props.toggleConfirmation(PRODUCT.id)}
                    checked={ this.props.SELECTED[PRODUCT.id].confirmed }
                />
            )
        }

        return '-';
    }

    renderAmountInput = () =>
    {
        let PRODUCT = this.props.PRODUCT;
        if (this.props.SELECTED[PRODUCT.id]) {
            return (
                <>
                <input 
                    key={PRODUCT.id + '_amount'}
                    type="number" 
                    min="0" 
                    onChange={ input => this.changeProductsAmount(input.target.value) }
                    value={ this.props.SELECTED[PRODUCT.id] ? this.props.SELECTED[PRODUCT.id].amount : 0 }
                />
                <span> {PRODUCT.ed_izm}</span>
                </>
            )
        }

        return '-';
    }

    renderOrderingData = () =>
    {
        if (!this.props.SUPPLIER) {
            return '-';
        }

        if (this.props.SUPPLIER.order_days.length > 0) {
            let headings = [];
            for (let i = 0; i < this.props.SUPPLIER.order_days.length; i++) {
                let title = this.getDayTitle(this.props.SUPPLIER.order_days[i])
                if (typeof title !== "undefined" && title.length > 0 && title !== ",") {
                    headings.push(title);
                }
            }

            let times = this.props.SUPPLIER.order_times.length ? <><br/>{this.props.SUPPLIER.order_times}</> : null
 
            return <>{headings.join(", ")}{times}</>;
        }
    }

    getDayTitle = (day) =>
    {
        let days = ['','Пн','Вт','Ср','Чт','Пт','Сб','Вс'];

        return days[day]
    }

    renderDeliveryData = () =>
    {
        if (!this.props.SUPPLIER) {
            return '-';
        }

        if (this.props.SUPPLIER.pickup == true || this.props.SUPPLIER.pickup == 1) {
            return 'Самовывоз';
        }

        if (this.props.SUPPLIER.delivery_days.length > 0) {
            let headings = [];
            for (let i = 0; i < this.props.SUPPLIER.delivery_days.length; i++) {
                let title = this.getDayTitle(this.props.SUPPLIER.delivery_days[i])
                if (typeof title !== "undefined" && title.length > 0 && title !== ",") {
                    headings.push(title);
                }
            }

            let times = this.props.SUPPLIER.delivery_times.length ? <><br/>{this.props.SUPPLIER.delivery_times}</> : null
            return <>{headings.join(", ")}{times}</>;
        }
    }

    getLateClass = (date) =>
    {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;

        if (today > date) {
            return ' late';
        }


        return '';
    }

    removeSupplierOption = async (id) =>
    {
        try {
            let SEND_DATA = {
                action: 'admin_removeProductSupplier',
                id: this.props.PRODUCT.id,
                supplier: id,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({SUPPLIERS: data, SUP_LOADED: true})
            }
        } catch (error) {
        }
    }

    getOtherSuppliers = async () =>
    {
        this.setState({SHOW_SUPPLIER_SELECT: true, SUPPLIERS: false, SUP_LOADED: false})
        try {
            let SEND_DATA = {
                action: 'admin_getProductSuppliers',
                id: this.props.PRODUCT.id,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({SUPPLIERS: data, SUP_LOADED: true})
            }
        } catch (error) {
        }
    }

    hideSuppliersModal = () =>
    {
        this.setState({SHOW_SUPPLIER_SELECT: false, SUPPLIERS: false, SUP_LOADED: false})
    }

    changeSelectedSupplier = (supplier) =>
    {
        this.props.setSupplierToOrdering(this.props.PRODUCT.id, supplier)
        this.hideSuppliersModal()
    }

    changeQuantAmount = (quant) =>
    {
        this.props.setQuantToOrdering(this.props.PRODUCT.id, quant)
    }

    changeOfdAmount = (days) =>
    {
        this.props.setOrderFromDaysToOrdering(this.props.PRODUCT.id, days)
    }

    renderOtherSupplier = () =>
    {
		return (
            <div className="select_search_holder">
                <SelectSearch 
                    filterOptions={fuzzySearch} 
                    search={true} 
                    id="supplierInModal" 
                    name="supplierInModal" 
                    options={this.props.SUPPLIERS_OPTIONS} 
                    placeholder="Выбрать поставщика" 
                    onChange={(val) => this.changeSelectedSupplier(val)}
                    value={''}
                />
                <img className="bangled" src="/system_images/blue_angle_down.png"/>
            </div>
        )
    }

    showSuppliersEdit = () =>
    {
        if (this.state.SHOW_SUPPLIER_EDIT) {
            return (
                <div className="or_modal">
                    <div className="or_overlay" onClick={() => this.setState({SHOW_SUPPLIER_EDIT: false})}></div>
                    <div className="or_body">
                        <a className="close-modal" onClick={() => this.setState({SHOW_SUPPLIER_EDIT: false})}>
                            <img src="/system_images/close.png" />
                        </a>
                        <h4>{this.props.SUPPLIER.title}</h4>
                        <SupplierEditForm
                            supplier={this.props.SUPPLIER}
                            updateSuppliers={this.props.updateSuppliers}
                        />
                    </div>
                </div>
            )
        }

        return null
    }

    showSuppliersSelect = () =>
    {
        if (this.state.SHOW_SUPPLIER_SELECT) {
            let SUPS = [];
            if (this.state.SUPPLIERS) {
                for (let i = 0; i < this.state.SUPPLIERS.length; i++) {
                    SUPS.push(
                        <div key={i}>
                            <a onClick={() => this.changeSelectedSupplier(this.state.SUPPLIERS[i].title)}>
                                <span>{this.state.SUPPLIERS[i].title}<br/>{this.state.SUPPLIERS[i].price} руб от {this.state.SUPPLIERS[i].date}</span>
                            </a>
                            <span onClick={() => this.removeSupplierOption(this.state.SUPPLIERS[i].id)}>
                                <img src="/system_images/close.png" />
                            </span>
                        </div>
                    )
                }
            }

            if (this.state.SUP_LOADED && (!this.state.SUPPLIERS || !this.state.SUPPLIERS.length)) {
                SUPS = <h5>К сожалению поставщиков в истории нет!</h5>
            }

            return (
                <div className="or_modal">
                    <div className="or_overlay" onClick={() => this.hideSuppliersModal()}></div>
                    <div className="or_body_ns">
                        <a className="close-modal" onClick={() => this.hideSuppliersModal()}>
                            <img src="/system_images/close.png" />
                        </a>
                        <h4>{this.props.PRODUCT.title}</h4>
                        <h6>Выберите поставщика:</h6>
                        <div className="or_suppliers_list">
                            {SUPS}
                        </div>
                        <h6>Другой поставщик:</h6>
                        {this.renderOtherSupplier()}
                    </div>
                </div>
            )
        }
    }

    renderBadges = () =>
    {
        let BADGES = []
        if (this.props.PRODUCT.archive == 1) {
            BADGES.push(<span title="Закрыт" className="da_small_badge" key="archive">З</span>)
        }
        if (this.props.PRODUCT.topack == 1) {
            BADGES.push(<span title="Фасовочный" className="da_small_badge" key="topack">Ф</span>)
        }
        if (this.props.PRODUCT.seasonstart !== this.props.PRODUCT.seasonend) {
            if (parseInt(this.props.PRODUCT.today_season) == 0) {
                BADGES.push(<span title="Сезонный" className="da_small_badge" key="season">С</span>)
            }
        }

        if (BADGES.length) {
            return <div className="da_small_badges">{BADGES}</div>
        }

        return null
    }

    renderSupPriceChange = (current, prev) =>
    {
        if (prev == 0) {
            return null
        }

        let diff = ((current/prev-1)*100).toFixed(2)

        if (diff < 0) {
            return (
                <span className="price_diff_change">
                    <img src="/system_images/tr_down.png"/> {diff}%
                </span>
            )
        } else if (diff > 0) {
            return (
                <span className="price_diff_change">
                    <img src="/system_images/tr_up.png"/> {diff}%
                </span>
            )
        }

        return null
    }

    renderIncomingAmount = () =>
    {
        if (this.props.PRODUCT.incoming_date) {
            return <small>{this.props.PRODUCT.incoming_amount} {this.props.PRODUCT.ed_izm}</small>
        }
    }

    getSales = async () =>
    {
        try {
            let SEND_DATA = {
                action: 'admin_getProductSales',
                id: this.props.PRODUCT.id,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({SALES: data, SHOW_SALES: true})
            }
        } catch (error) {
        }
    }

    getSelectedClass = (date) =>
    {
        if (typeof this.state.SALES_SELECTED[date] !== "undefined") {
            return ' active';
        }

        return '';
    }

    toggleSelectivness = (date, amount) =>
    {
        let SELECTED = Object.assign({},{...this.state.SALES_SELECTED})
        if (typeof SELECTED[date] !== "undefined") {
            delete SELECTED[date]
        } else {
            SELECTED[date] = amount
        }

        this.setState({SALES_SELECTED: SELECTED})
    }

    getButtonSelectivenessClass = () =>
    {
        if (Object.keys(this.state.SALES_SELECTED).length > 0) {
            return '';
        }

        return ' inactive';
    }

    recountDepending = () =>
    {
        let TOTAL = 0;
        let DAYS = 0;
        for (let key in this.state.SALES_SELECTED) {
            TOTAL += parseFloat(this.state.SALES_SELECTED[key]);
            DAYS++;
        }
        this.props.setSpecialCounting(this.props.PRODUCT.id, TOTAL, DAYS)
        this.setState({SHOW_SALES: false})
    }

    showProductSales = () =>
    {
        if (!this.state.SHOW_SALES) {
            return;
        }

        let content = '';
        if (!this.state.SALES.length) {
            content = 'Продаж в базе не обнаружено!'
        } else {
            content = [(
                <div key="zero" className="sales_column">
                    <div className="sales_column_header">
                        Дата
                    </div>
                    <div className="sales_column_header">
                        Продажи
                    </div>
                </div>
            )]
            for (let i = 0; i < this.state.SALES.length; i++) {
                let day = this.state.SALES[i];

                content.push(
                    <div onClick={() => this.toggleSelectivness(day.date, day.sales)} key={i} className={ 'sales_column' + this.getSelectedClass(day.date)}>
                        <div>
                            {day.date}<br/>{day.weekday}
                        </div>
                        <div>
                            {day.sales} {this.props.PRODUCT.ed_izm}
                        </div>
                    </div>
                )
            }

            content = (
                <>
                    <div className="sales_row">
                        {content}
                    </div>
                    <br/>
                    <a className={'submit_order_to_suppliers' + this.getButtonSelectivenessClass()} onClick={() => this.recountDepending()}>Пересчитать</a>
                </>
            )
        }

        return (
            <div className="or_modal">
                <div className="or_overlay" onClick={() => this.setState({SHOW_SALES: false})}></div>
                <div className="or_body_ns">
                    <a className="close-modal" onClick={() => this.setState({SHOW_SALES: false})}>
                        <img src="/system_images/close.png" />
                    </a>
                    <h4>{this.props.PRODUCT.title}</h4>
                    <h6>Продажи:</h6>
                    {content}
                </div>
            </div>
        )
    }

    toggleHidden = async () =>
    {
        let HIDDEN = []

        if (this.props.HIDDEN.includes(this.props.PRODUCT.id.toString())) {
            for (let i = 0; i < this.props.HIDDEN.length; i++) {
                if (this.props.HIDDEN[i] !== this.props.PRODUCT.id.toString()) {
                    HIDDEN.push(this.props.HIDDEN[i])
                }
            }
        } else {
            HIDDEN = [...this.props.HIDDEN]
            HIDDEN.push(this.props.PRODUCT.id.toString())
        }

        this.props.setHidden(HIDDEN)

        try {
            let SEND_DATA = {
                action: 'admin_toggleHidden',
                id: this.props.PRODUCT.id,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

        } catch (error) {
        }
    }

    renderHiddenEye = () =>
    {
        let className = "hiddenToggler"
        if (this.props.HIDDEN.includes(this.props.PRODUCT.id.toString())) {
            className = "hiddenToggler active"
        }

        return <a className={className} onClick={() => this.toggleHidden()}>
            <i className="fa fa-eye"></i>
        </a>
    }

    render()
    {
        let PRODUCT = this.props.PRODUCT;
        let SRC = PRODUCT.thumb && PRODUCT.thumb.length ? CONFIG.API_C_DOMAIN + PRODUCT.thumb : CONFIG.API_C_DOMAIN + '/imgs/no-foto.png';
// console.log(PRODUCT.id, PRODUCT.title, PRODUCT)
        return (
            <>
                <div className="op_id">{this.renderHiddenEye()}</div>
                <div className="op_id">{PRODUCT.id}</div>
                <div className="op_image"><a onClick={() => this.props.openProductModal(PRODUCT.id, PRODUCT)}><img src={SRC} />{this.renderBadges()}</a></div>
                <div className="op_title">{PRODUCT.title}</div>
                {this.renderFav()}
                
                {
                    this.props.VIEW.amount && (<div className="op_amount">{PRODUCT.real_amount} {PRODUCT.ed_izm}</div>)
                }
                {
                    this.props.VIEW.sales_10 && (<div className="op_sales_10"><a onClick={() => this.getSales()}>{PRODUCT.sales_10} {PRODUCT.ed_izm}</a></div>)
                }
                {
                    this.props.VIEW.on_site && (<div className="op_on_site">{PRODUCT.last_on_ware}</div>)
                }
                {
                    this.props.VIEW.planned && (<div className={"op_planned" + this.getLateClass(PRODUCT.incoming_date)}>{PRODUCT.incoming_date}{this.renderIncomingAmount()}</div>)
                }
                {
                    this.props.VIEW.minimum_quantity && (
                        <div className="op_order_days">
                            <input 
                                key={PRODUCT.id + '_minimum_quantity'}
                                type="number" 
                                min="0" 
                                onChange={ input => this.changeMinimumQuanity(input.target.value) }
                                defaultValue={ PRODUCT.minimum_quantity }
                            />
                        </div>
                    )
                }
                {
                    this.props.VIEW.maximum_quantity && (
                        <div className="op_order_days">
                            <input 
                                key={PRODUCT.id + '_maximum_quantity'}
                                type="number" 
                                min="0" 
                                onChange={ input => this.changeMaximumQuanity(input.target.value) }
                                defaultValue={ PRODUCT.maximum_quantity }
                            />
                        </div>
                    )
                }
                {
                    this.props.VIEW.order_days && (
                        <div className="op_order_days">
                            <input 
                                key={PRODUCT.id + '_days'}
                                type="number" 
                                min="0" 
                                onChange={ input => this.changeDaysAmount(input.target.value) }
                                defaultValue={ this.props.SELECTED[PRODUCT.id] ? this.props.SELECTED[PRODUCT.id].days : 0 }
                            />
                        </div>
                    )
                }
                {
                    this.props.VIEW.order_quant && (
                        <div className="op_quant">
                            <input 
                                key={PRODUCT.id + '_order_quant'}
                                type="number" 
                                min="0" 
                                onChange={ input => this.changeQuantAmount(input.target.value) }
                                defaultValue={ PRODUCT.order_quant }
                            />
                        </div>
                    )
                }
                {
                    this.props.VIEW.to_order && (<div className="op_to_order">
                        {this.renderAmountInput()}
                    </div>)
                }
                {
                    this.props.VIEW.order_from_days && (
                        <div className="op_quant">
                            <input 
                                key={PRODUCT.id + '_order_from_days'}
                                type="number" 
                                min="0" 
                                onChange={ input => this.changeOfdAmount(input.target.value) }
                                defaultValue={ PRODUCT.order_from_days }
                            />
                        </div>
                    )
                }
                {
                    this.props.VIEW.order_sum && (<div className="op_order_sum">{ this.props.SELECTED[PRODUCT.id] ? Math.round(this.props.SELECTED[PRODUCT.id].amount * PRODUCT.pure_sup_price) + ' р' : '-' }</div>)
                }
                {
                    this.props.VIEW.order_confirm && (<div className="op_order_confirm">
                        {this.renderCheckbox()}
                    </div>)
                }
                {
                    this.props.VIEW.supplier && (<div className="op_supplier">
                        <a className="table_flex" onClick={() => this.getOtherSuppliers()}>{this.props.SUPPLIER.title}<img src="/system_images/tr_down.png"/></a>
                    </div>)
                }
                {
                    this.props.VIEW.till_when && (<div className="op_till_when">
                        {this.renderOrderingData()}
                    </div>)
                }
                {
                    this.props.VIEW.sup_incomings && (<div className="op_sup_incomings">
                        <div><div>{this.renderDeliveryData()}</div><a onClick={() => this.setState({SHOW_SUPPLIER_EDIT: true})}><img src="/system_images/green_pencil.png"/></a></div>
                    </div>)
                }
                {
                    this.props.VIEW.barcode && (<div className="op_barcode">{PRODUCT.barcode}</div>)
                }
                {
                    this.props.VIEW.sup_price && (<div className="op_sup_price">{PRODUCT.pure_sup_price} Р{this.renderSupPriceChange(PRODUCT.pure_sup_price,PRODUCT.prev_sup_price)}</div>)
                }
                {
                    this.props.VIEW.writeoffs_30 && (<div className="op_writeoffs_30">{PRODUCT.writeoffs} Р</div>)
                }
                {
                    this.props.VIEW.group && (<div className="op_group">{this.getProductGroups(PRODUCT.all_groups)}</div>)
                }
                {
                    this.props.VIEW.coef && (<div className="op_coef">{PRODUCT.peramount}</div>)
                }
                {
                    this.props.VIEW.expiration && (<div className="op_expiration">{PRODUCT.days_alive} д</div>)
                }
                {
                    this.props.VIEW.manager && (<div className="op_manager">{PRODUCT.manager}</div>)
                }
                {
                    this.props.VIEW.left_sum && (<div className="op_left_sum">{Math.round(PRODUCT.real_amount * PRODUCT.normal_price)} р</div>)
                }
                {
                    this.props.VIEW.marge && (<div className="op_marge">{PRODUCT.marge} %</div>)
                }
                {
                    this.props.VIEW.sup_nom && (<div className="op_sup_nom">{PRODUCT.vendor_code}</div>)
                }
                {this.showSuppliersSelect()}
                {this.showSuppliersEdit()}
                {this.showProductSales()}

                {
                    this.props.VIEW.change_data_percent && (<div className="op_sup_nom">{PRODUCT.price_change_data ? PRODUCT.price_change_data.percent : '-'}</div>)
                }
                {
                    this.props.VIEW.change_data_start_price && (<div className="op_sup_nom">{PRODUCT.price_change_data ? PRODUCT.price_change_data.start_price : '-'}</div>)
                }
                {
                    this.props.VIEW.change_data_end_price && (<div className="op_sup_nom">{PRODUCT.price_change_data ? PRODUCT.price_change_data.end_price : '-'}</div>)
                }
                {
                    this.props.VIEW.change_data_change_sum && (<div className={PRODUCT.price_change_data ? PRODUCT.price_change_data?.percent > 10 ? "op_sup_nom errored" : "op_sup_nom" : "op_sup_nom" }>{PRODUCT.price_change_data ? Math.round(PRODUCT.price_change_data.price_change * PRODUCT.sales_10 * 3) : '-'}</div>)
                }
                {
                    this.props.VIEW.change_data_supplier && (<div className="op_manager">{PRODUCT.price_change_data ? this.getSupplier(PRODUCT.id, PRODUCT.price_change_data.supplier, this.props.SUPPLIERS) : '-'}</div>)
                }
            </>
        )
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OrderingProductRow);